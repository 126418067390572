import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["dropDownSection", "linkIcon"]
    toggleSection() {
        this.dropDownSectionTarget.classList.toggle("hidden");
        this.updateLinkTextAndIcon();
    }

    updateLinkTextAndIcon() {
        const isSectionVisible = !this.dropDownSectionTarget.classList.contains("hidden");
        const iconPath = isSectionVisible ? 'chevron-up.svg' : 'chevron-down.svg';
        this.linkIconTarget.src = `/assets/${iconPath}`;
        this.element.querySelector('a').prepend(this.linkIconTarget);
    }
}
